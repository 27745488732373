<template>
    <div class="agreement">
        <div class="agreement-main">
            <p class="strong">甲方：四川万马投流传媒科技有限公司（以下简称：本网站）</p>
            <p class="strong">乙方：灵龙会员和DNS学员（以下简称：用户）</p>
            <p class="default-style mt-20">
                甲乙双方在平等、自愿、友好协商的基础上，针对乙方参加甲方课程培训事宜达成如下协议，规范双方的教学与学习行为，以兹共同遵照履行：
            </p>
            <p class="default-style mt-5">
                1.在用户决定购买本次收费课程网络课程之前，请仔细阅读本收费协议条款。用户应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。用户注册程序完成即表示完全接受本协议项下的全部条款。
            </p>
            <p class="default-style mt-5">
                2.对于未明确授权给用户的收费网络课程及其他服务的权利、收益由本网站或相关权利人所有。
            </p>
            <p class="default-style mt-5">
                3.用户应妥善保存账户注册时所填写的信息。为了防止不法分子对本网站的使用以及网站自身稳定性，本网站将不时对本网站的登录与使用进行身份验证。用户注册时所填写的信息将成为网站验证的唯一凭证。因用户注册信息丢失造成网站不能正常使用的，本网站不承担法律责任。
            </p>
            <p class="default-style mt-5">
                4.用户应自行负责保管、使用、维护其在本网站申请取得的账户及账户密码。用户应对其账户密码采取必要和有效的保密措施非本网站原因致使用户账户密码泄漏而造成损失的，本网站无须承担与此有关的任何责任。如果用户的账户、密码丢失的，应在在本次收费网络课程结束前及时联系本网站，并根据本网站要求提供相应的付款凭证以及信息。如果能够证实用户账户密码丢失真实存在的，本网站将恢复用户权益。如果不能提供有效凭证的，其中损失由用户承担。
            </p>
            <p class="default-style mt-5">
                5.用户同意本协议，即视为用户认可本次收费网络课程标明的价格；用户购买成功后，该项服务即时生效。
            </p>
            <p class="default-style mt-5">
                6.对于具体购买操作步骤，本网站会在相关页面上做每一步的明确提示。
            </p>
            <p class="default-style mt-5">
                7.如果用户同意购买本次收费网络课程，本网站将根据本次课程中标明的费用从用户在网站账户中扣划相应的费用。扣款成功的本协议生效。
            </p>
            <p class="default-style mt-5">
                8.用户一旦购买本次收费网络课程即视为认可本次授课时间。如用户未在有效时间内参加的服务网络课程的授课，视为用户已全部观看，本网站概不退款。
            </p>
            <p class="default-style mt-5">
                9.本网站对本次收费网络课程的授课时间保留随时变更的权利，而不需通知用户。如果用户不同意上述变更的，应及时申请退款。如果用户并未退款，视为用户接受该课程变更。
            </p>
            <p class="default-style mt-5">
                10.用户应在课程有效期内观看完本次收费网络课程的全部课程内容并任选其中一期参加社群服务。在课程有效期内，用户可以不限时段反复观看全部课程内容并做相关练习。有效期满，视为用户已观看完全部课程内容并已参加完全部社群服务内容，相关权限自动关闭。
            </p>
            <p class="default-style mt-5">
                11.用户在使用本网站所提供的服务时，如遭受任何人身或财务的损失、损害或伤害，不论原因如何，本网站均不负责任。由于用户将个人密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露，本网站不负任何责任。对于所有服务的中断或终止而造成的任何损失，本网站无需对用户或任何第三方承担任何责任。
            </p>
            <p class="default-style mt-5">
                12.对于用户购买的收费网络课程，用户只可以私人观看，不得用于商业、盈利等用途。用户同意不得对授课内容录音录像，不得将网络课程内容复制、销售、出租或授权给任何第三方。如本网站发现用户存在上述行为的，本网站有权停止用户对本次课程的收听。用户的网站使用账户不得出售。如果用户擅自出售网站账户的，本网站有权终止该账户的使用，且收取费用不予退还。
            </p>
            <p class="default-style mt-5">
                13.当用户不得通过非本网站认可的方式获得收费许可网络课程，或者删除收费网络课程上的任何所有权声明或标签。
            </p>
            <p class="default-style mt-5">
                14.用户在收听本次收费网络课程时应尊重他人合法权益。如果在收听过程中用户侵犯他人权益、扰乱正常的收听秩序或者发布不当言论的（包括且不限于下述举例中情形），本网站有权终止用户的收听。对于已收取且消费的费用不予退还。举例：
            </p>
            <p class="default-style mt-5">
                •&nbsp;在直播中、班级群内有明显的不尊重同学、老师的行为，警告一次之后仍有发生
            </p>
            <p class="default-style mt-5">
                •&nbsp;在与主讲老师、辅导老师、规划顾问沟通中有明显的出言不逊
            </p>
            <p class="default-style mt-5">
                •&nbsp;在班级群内传播不当政治言论、负面信息、乱发广告，警告一次之后仍有发生
            </p>
            <p class="default-style mt-5">
                •&nbsp;在结课之前向其他同学索要用户名密码观看自己未报名的科目
            </p>
            <p class="default-style mt-5">
                •&nbsp;在结课之前通过任何方式煽动其他同学向老师索要结课后的资料
            </p>
            <p class="default-style1 mt-20">
                反盗链声明：本公司已经对本网站内全部正版视频内容采取了必要的反盗版和防盗链等技术措施，并且添加、设置权利管理电子信息。 任何单位或个人，未经本公司许可，不得以任何方式（包括但不限于：盗链、冗余盗取等）直接或间接地盗取相关视频内容、不得以任何方式（包括但不限于：隐藏或者修改本网站域名、播放器软件、标识等）删除或者改变相关视频内容的权利管理电子信息。本公司将保留进一步追究侵权者法律责任的权利。
            </p>
            <p class="default-style1 mt-5">
                1.DNS为专业抖音达人培训网站，非经特殊说明，DNS网站中的视频作品（包括但不限于DNS在线直播课程、录播课程、公开课、课件、讲义、题目等）均由四川万马投流传媒科技有限公司独立创作完成，此作品为法人作品，著作权及相邻权等一切权利归四川万马投流传媒科技有限公司所有，四川万马投流传媒科技有限公司对此承担一切法律责任。
            </p>
            <p class="default-style1 mt-5">
                2.DNS网站中所发布的在线直播课程、录播课程及相关视频作品的创作过程得到了作品表演者、组织者的全部授权。四川万马投流传媒科技有限公司拥有上述视频作品完整的著作权及邻接权。
            </p>
            <p class="default-style1 mt-5">
                3.对于DNS网站中所发布的原创作品，任何媒体、网站或个人未经本网协议授权不得转载、链接、转贴或以其他方式复制发布、销售、下载、发表。所有在线课程只允许购买者在线观看学习、缓存下载，不可销售、传播。对于上述版权内容，超越合理使用范畴且未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。
            </p>
        </div>
        <div class="flex flex-center align-center btn">
            <van-button style="width: 345px;" color="#E62129" round @click="goBackFunc">阅读并已同意协议</van-button>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goBackFunc() {
            this.$router.go(-1);
        }
    }
}
</script>
<style lang="scss" scoped>
.agreement{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #F6F6F6;
    &-main{
        width: auto;
        height: auto;
        padding: 20px 15px 80px;
        .strong{
            width: 100%;
            height: auto;
            color: #333333;
            font-size: 14px;
            font-weight: bold;
        }
        .default-style{
            width: 100%;
            height: auto;
            color: #666;
            font-size: 14px;
            font-weight: 400;
        }
        .default-style1{
            width: 100%;
            height: auto;
            color: #333;
            font-size: 14px;
            font-weight: 400;
        }
        .mt-20{
            margin-top: 20px;
        }
        .mt-5{
            margin-top: 8px;
        }
    }
    .btn{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 60px;
        background-color: #fff;
    }
}
</style>